import React from 'react'
import { graphql } from 'gatsby'
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { Menu } from 'components/Navigation';
import Layout from '../components/Layout'
import SEO from '../components/seo'

class Page extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About Us" />
        <Menu colorScheme={`dark`} />
        <h1>About Us</h1>
          <Query query={APOLLO_QUERY}>
            {({ data, loading, error }) => {
              // console.log(`data`, data);
              if (loading) return <p>Loading pupper...</p>;
              if (error) {
                // console.log(`error`, error);
                return <p>Error: ${error.message}</p>;
              }

              const {
                image: src,
                name
              } = data.character;
              return (
                <div>
                  <h1>{name}</h1>
                  <img src={src} alt={name} style={{ maxWidth: 300 }} />
                </div>
              );
            }}
          </Query>
      </Layout>
    )
  }
}
// This query is executed at run time by Apollo.
const APOLLO_QUERY = gql`
  {
    character(id: 1) {
      name
      image
    }
  }
`;

export default Page;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
